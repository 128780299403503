@font-face {
  font-family: 'Articulat CF';
  src: url('subset-ArticulatCF-Bold.eot');
  src: url('subset-ArticulatCF-Bold.eot?#iefix') format('embedded-opentype'),
    url('subset-ArticulatCF-Bold.woff2') format('woff2'),
    url('subset-ArticulatCF-Bold.woff') format('woff'),
    url('subset-ArticulatCF-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('subset-ArticulatCF-ExtraBold.eot');
  src: url('subset-ArticulatCF-ExtraBold.eot?#iefix')
      format('embedded-opentype'),
    url('subset-ArticulatCF-ExtraBold.woff2') format('woff2'),
    url('subset-ArticulatCF-ExtraBold.woff') format('woff'),
    url('subset-ArticulatCF-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('subset-ArticulatCF-Heavy.eot');
  src: url('subset-ArticulatCF-Heavy.eot?#iefix') format('embedded-opentype'),
    url('subset-ArticulatCF-Heavy.woff2') format('woff2'),
    url('subset-ArticulatCF-Heavy.woff') format('woff'),
    url('subset-ArticulatCF-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('subset-ArticulatCF-Medium.eot');
  src: url('subset-ArticulatCF-Medium.eot?#iefix') format('embedded-opentype'),
    url('subset-ArticulatCF-Medium.woff2') format('woff2'),
    url('subset-ArticulatCF-Medium.woff') format('woff'),
    url('subset-ArticulatCF-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('subset-ArticulatCF-DemiBold.eot');
  src: url('subset-ArticulatCF-DemiBold.eot?#iefix') format('embedded-opentype'),
    url('subset-ArticulatCF-DemiBold.woff2') format('woff2'),
    url('subset-ArticulatCF-DemiBold.woff') format('woff'),
    url('subset-ArticulatCF-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Articulat CF';
  src: url('subset-ArticulatCF-Text.eot');
  src: url('subset-ArticulatCF-Text.eot?#iefix') format('embedded-opentype'),
    url('subset-ArticulatCF-Text.woff2') format('woff2'),
    url('subset-ArticulatCF-Text.woff') format('woff'),
    url('subset-ArticulatCF-Text.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
