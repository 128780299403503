@font-face {
  font-family: 'Manifold CF';
  src: url('subset-ManifoldCF-Bold.eot');
  src: url('subset-ManifoldCF-Bold.eot?#iefix') format('embedded-opentype'),
    url('subset-ManifoldCF-Bold.woff2') format('woff2'),
    url('subset-ManifoldCF-Bold.woff') format('woff'),
    url('subset-ManifoldCF-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
